import React, { useEffect } from 'react';
import {
  createBrowserRouter,
  RouterProvider,
  useLocation,
} from "react-router-dom";
import { Container, Card, CardMedia, CardContent, Typography, CardActions, CardActionArea, Button } from '@mui/material';
import Grid from '@mui/material/Grid2';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import './App.css';
import Lightbox from "yet-another-react-lightbox";
import Zoom from "yet-another-react-lightbox/plugins/zoom";
import "yet-another-react-lightbox/styles.css";
import { initializeApp } from "firebase/app";
import { getAnalytics, logEvent } from "firebase/analytics";

const theme = createTheme({
  // Customize your theme here
});

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_APIKEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messageSenderId: process.env.REACT_APP_FIREBASE_MESSAGE_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
};

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

function ShowCard({ title, description, logo, slides }) {
  const [open, setOpen] = React.useState(false);


  return (
    <Card
      sx={{ maxWidth: 345 }}
      onClick={() => setOpen(true)}
    >
      <CardActionArea>
        <CardMedia
          component="img"
          height="200"
          image={logo}
          alt={title}
          sx={{ objectFit: 'cover', objectPosition: 'top' }}
        />
        <CardContent>
          <Typography gutterBottom variant="h5" component="div">
            {title}
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }}>
            {description}
          </Typography>
          <ShowLightbox
            open={open}
            close={() => setOpen(false)}
            slides={slides}
          />
        </CardContent>
        <CardActions>
          <Button size="small" onClick={() => setOpen(true)}>Abrir</Button>
        </CardActions>
      </CardActionArea>
    </Card>
    )
}

function ShowLightbox({ open, close, slides }) {
  return (
    <>
      <Lightbox
        carousel={{ finite: true }}
        open={open}
        close={close}
        slides={slides} // Use the slides prop
        plugins={[Zoom]}
      />
    </>
  );
}

function generateImgUrl(img) {
  const baseUrl = 'https://firebasestorage.googleapis.com/v0/b/o-mamaco-consciente.firebasestorage.app/o';
  const queryParams = '?alt=media';
  return `${baseUrl}/${img}${queryParams}`;
}

function Home() {
  usePageViews();

  const cap1 = [
    { src: generateImgUrl('c1p1.png') },
    { src: generateImgUrl('c1p2.png') },
    { src: generateImgUrl('c1p3.png') },
    { src: generateImgUrl('c1p4.png') },
    { src: generateImgUrl('c1p5.png') },
    { src: generateImgUrl('c1p6.png') },
    { src: generateImgUrl('c1p7.png') },
    { src: generateImgUrl('c1p8.png') },
    { src: generateImgUrl('c1p9.png') },
    { src: generateImgUrl('c1p10.png') },
    { src: generateImgUrl('c1p11.png') },
    { src: generateImgUrl('c1p12.png') },
    { src: generateImgUrl('c1p13.png') },
  ];

  const cap2 = [
    { src: generateImgUrl('c2p1.png') },
    { src: generateImgUrl('c2p2.png') },
    { src: generateImgUrl('c2p3.png') },
    { src: generateImgUrl('c2p4.png') },
    { src: generateImgUrl('c2p5.png') },
    { src: generateImgUrl('c2p6.png') },
    { src: generateImgUrl('c2p7.png') },
    { src: generateImgUrl('c2p8.png') },
    { src: generateImgUrl('c2p9.png') },
    { src: generateImgUrl('c2p10.png') },
    { src: generateImgUrl('c2p11.png') },
    { src: generateImgUrl('c2p12.png') },
  ];

  const cap3= [
    { src: generateImgUrl('c3p1.png') },
    { src: generateImgUrl('c3p2.png') },
    { src: generateImgUrl('c3p3.png') },
    { src: generateImgUrl('c3p4.png') },
    { src: generateImgUrl('c3p5.png') },
    { src: generateImgUrl('c3p6.png') },
    { src: generateImgUrl('c3p7.png') },
    { src: generateImgUrl('c3p8.png') },
    { src: generateImgUrl('c3p9.png') },
    { src: generateImgUrl('c3p10.png') },
    { src: generateImgUrl('c3p11.png') },
    // Keep the extension in uppercase
    { src: generateImgUrl('c3p12.PNG') },
  ];

  return (
    <ThemeProvider theme={theme}>
      <Container maxWidth="lg">
        <Typography variant="h2" component="h1" gutterBottom align="center" sx={{ marginBottom: 0 }}>
          O Mamaco Consciente
        </Typography>
        <Typography variant="h5" component="h2" gutterBottom align="center" sx={{ marginBottom: 2 }}>
          De Miguel Araujo e Bruno Fonseca
        </Typography>
        <Grid container direction="column" justifyContent="center" alignItems="center" spacing={2}>
          <Grid item>
            <ShowCard
              title="Capítulo I: Rotina"
              description="Onde os agiotas precisam lidar com o caso Jeorge Tyler."
              slides={cap1}
              logo={generateImgUrl('c1.jpeg')} />
          </Grid>
          <Grid item>
            <ShowCard
              title="Capítulo II: Carl Jabobs" 
              description="Apresentamos nosso antagonista, que depois de uma tragédia, inicia seu plano de vingança."
              slides={cap2}
              logo={generateImgUrl('c2.png')} />
          </Grid>
          <Grid item>
            <ShowCard
              title="Capítulo III: S.W.A.T."
              description="Os agiotas conseguem dinheiro para financiar seu plano de vingança."
              slides={cap3}
              logo={generateImgUrl('c3.png')} />
          </Grid>
        </Grid>
      </Container>
    </ThemeProvider>
  );
}

function usePageViews() {
  const location = useLocation();

  useEffect(() => {
    logEvent(analytics, 'page_view', {
      page_path: location.pathname,
      page_title: document.title,
      page_location: window.location.href,
      page_domain: window.location.hostname,
    });
  }, [location]);
}

function App() {
  const router = createBrowserRouter([
  {
    path: "/",
    element: <Home />,
  },
]);

  return (
    <RouterProvider router={router} />
  )
}

export default App;
